@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .square {
    aspect-ratio: 1 / 1;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.btn {
  @apply shadow-md py-3 px-6 rounded-md transition duration-300;
}
/* .btn-purple {
	@apply bg-bookmark-purple text-white;
} */
/* .btn-white {
	@apply bg-bookmark-white;
} */

:root {
  --heights: 80vh;
  --widths: 100%;
  --font-size: 1.2rem;
  --offWhite: #f7f7f7;
  --text-white: #fff;
  --line-height: 1.6;
  --letter-spacing: 2;
  --text-color: rgb(172, 108, 108);
  --default-color: #000;
  --primary-color: #f30e4c;
  --dark-grey: #cfcfcf;
  --transition: all 0.3s linear;
  --font-weight: 600;
  --light-shadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.5);
  --dark-shadow: 4px 10px 5px 0px rgba(0, 0, 0, 0.5);
}

.height {
  height: 100vh;
}
body {
  overflow-x: hidden;
}
main {
  min-height: 100vh;
}

/* About Style */
.mission .mission-text {
  background: var(--default-color);
  color: var(--text-color);
  padding: 20px;
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.centered {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
/* Nav */

.nav-link {
  @apply mx-4 px-4 py-2 rounded-xl text-sky-700 leading-8 tracking-widest font-semibold cursor-pointer;
}

.active {
  @apply text-sky-700 text-white;
}
